import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../firebaseConfig";
import Button from "../components/Button";
import { onAuthStateChanged } from "firebase/auth";

const BeerManagement = () => {
  const [breweryData, setBreweryData] = useState(null);
  const [beers, setBeers] = useState([]);
  const [newBeer, setNewBeer] = useState("");
  const [avgPrice, setAverageBeerPrice] = useState(0);
  const [location, setLocation] = useState("");
  const [searchBox, setSearchBox] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User UID:", user.uid);
        const brewerySnapshot = await getDoc(
          doc(firestore, "breweries", user.uid)
        );

        if (brewerySnapshot.exists()) {
          console.log("Brewery Data:", brewerySnapshot.data());
          setBreweryData(brewerySnapshot.data());
          setBeers(brewerySnapshot.data().beers || []);
          setAverageBeerPrice(brewerySnapshot.data().avgPrice || 0);
        } else {
          console.log("No brewery data found for this UID.");
        }
      } else {
        console.log("No user logged in.");
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const handleAddBeer = async () => {
    if (newBeer.trim() === "") {
      return;
    }

    const updatedBeers = [...beers, newBeer];
    setBeers(updatedBeers);
    setNewBeer("");

    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      beers: updatedBeers,
    });
  };

  const handleRemoveBeer = async (beerToRemove) => {
    const updatedBeers = beers.filter((beer) => beer !== beerToRemove);
    setBeers(updatedBeers);

    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      beers: updatedBeers,
    });
  };

  const handleUpdateAverageBeerPrice = async () => {
    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      avgPrice: parseFloat(avgPrice),
    });
  };

  const handleLocationSelect = (place) => {
    setLocation(place.formatted_address);
  };

  const handleUpdateLocation = async () => {
    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      location: location,
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="w-96">
        <h2 className="text-2xl font-semibold mb-6">Beer Management</h2>
        <div className="mb-4">
          <label
            htmlFor="avgPrice"
            className="block text-sm font-medium text-gray-700"
          >
            Average Beer Price
          </label>
          <input
            type="number"
            step="0.01"
            id="avgPrice"
            name="avgPrice"
            value={avgPrice}
            onChange={(e) => setAverageBeerPrice(e.target.value)}
            className="mt-1 block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <Button
            className="w-full mt-2"
            onClick={handleUpdateAverageBeerPrice}
          >
            Update Average Beer Price
          </Button>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Add a new beer"
            className="border border-gray-300 p-2 rounded w-full"
            value={newBeer}
            onChange={(e) => setNewBeer(e.target.value)}
          />
          <Button className="w-full mt-2" onClick={handleAddBeer}>
            Add Beer
          </Button>
        </div>
        <ul>
          {beers.map((beer, index) => (
            <li key={index} className="flex items-center justify-between mb-2">
              <span>{beer}</span>
              <Button
                className="text-red-500"
                onClick={() => handleRemoveBeer(beer)}
              >
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BeerManagement;
