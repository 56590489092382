import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { auth, firestore } from "../firebaseConfig";
import Card from "../components/Card";
import Form from "../components/Form";
import Input from "../components/Input";
import Button from "../components/Button";

const BrewerySignup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    breweryName: "",
    location: "",
    avgPrice: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { breweryName, location, avgPrice, email, password } = formData;

    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await setDoc(doc(firestore, "breweries", user.uid), {
        ownerUID: user.uid,
        name: breweryName,
        location: location,
        avgPrice: avgPrice,
      });

      navigate("/");
    } catch (error) {
      console.error("Error signing up the brewery:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <Card className="w-96">
        <h2 className="text-2xl font-semibold mb-6">Brewery Sign Up</h2>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Brewery Name"
            type="text"
            name="breweryName"
            value={formData.breweryName}
            onChange={handleChange}
          />
          <Input
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <Input
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          <Button type="submit" className="w-full">
            Sign Up
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default BrewerySignup;
