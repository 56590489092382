import React from "react";
import Footer from "./components/Footer";
import AppRouter from "./AppRouter";
import { LoadScript } from "@react-google-maps/api";

const googleMapsLibraries = ["places"];

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <LoadScript googleMapsApiKey="AIzaSyDOtp-IOcR8g6BSjTx5T7guD5X1-o1kF2k" libraries={["places"]}>
          <AppRouter />
        </LoadScript>
      </div>
      <Footer />
    </div>
  );
};

export default App;
