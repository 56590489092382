import React from "react";

const Input = ({ label, type, name, value, onChange, className }) => {
  return (
    <div className={`flex flex-col space-y-1 ${className}`}>
      <label htmlFor={name} className="text-sm font-semibold">
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="border-2 border-gray-300 p-2 rounded"
      />
    </div>
  );
};

export default Input;
