import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  // Your Firebase configuration object
  apiKey: "AIzaSyBre1Tx2-NDWgHb46DZ6oqjjJ6_B0cEO0k",
  authDomain: "cold-one-f8626.firebaseapp.com",
  projectId: "cold-one-f8626",
  storageBucket: "cold-one-f8626.appspot.com",
  messagingSenderId: "200094573473",
  appId: "1:200094573473:web:65195768230519bfc561e0",
  measurementId: "G-34TN5V5ZW2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, auth, firestore };
