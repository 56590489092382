import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import BrewerySignup from "./pages/BrewerySignup";
import BreweryLogin from "./pages/BreweryLogin";
import BreweryDashboard from "./pages/BreweryDashboard";
import BeerManagement from "./pages/BeerManagement";
import ProtectedRoute from "./components/ProtectedRoute.js";
import Navbar from "./components/Navbar";
import NotFound from "./pages/NotFound";
import BreweryProfile from "./pages/BreweryProfile";
import BreweryList from "./pages/BreweryList";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<BrewerySignup />} />
        <Route path="/login" element={<BreweryLogin />} />
        <Route
          path="/brewery/dashboard"
          element={
            <ProtectedRoute>
              <BreweryDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/brewery/management"
          element={
            <ProtectedRoute>
              <BeerManagement />
            </ProtectedRoute>
          }
        />
        <Route path="/breweries/:breweryId" element={<BreweryProfile />} />
        <Route path="/breweries" element={<BreweryList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
