export const mockBreweries = [
    {
      id: 1,
      name: "Brewery Alpha",
      address: "123 Alpha St",
      latitude: 40.73061,
      longitude: -73.935242,
      description: "Brewery Alpha is known for its wide variety of unique, handcrafted beers.",
      beers: [
        {
          id: 1,
          name: "Alpha IPA",
          style: "IPA",
          description: "A bold and hoppy IPA with notes of citrus and pine.",
        },
        {
          id: 2,
          name: "Alpha Lager",
          style: "Lager",
          description: "A crisp and refreshing lager with a clean finish.",
        },
      ],
    },
    {
      id: 2,
      name: "Brewery Beta",
      address: "456 Beta St",
      latitude: 40.74061,
      longitude: -73.945242,
      description: "Brewery Beta is a small-batch brewery that specializes in unique flavor combinations.",
      beers: [
        {
          id: 3,
          name: "Beta Sour",
          style: "Sour",
          description: "A tart and fruity sour beer with a hint of sweetness.",
        },
        {
          id: 4,
          name: "Beta Stout",
          style: "Stout",
          description: "A rich and creamy stout with notes of chocolate and coffee.",
        },
      ],
    },
    // ...
    {
      id: 10,
      name: "Brewery Omega",
      address: "789 Omega St",
      latitude: 40.76061,
      longitude: -73.955242,
      description: "Brewery Omega is a neighborhood brewery with a focus on classic beer styles.",
      beers: [
        {
          id: 19,
          name: "Omega Pilsner",
          style: "Pilsner",
          description: "A light and refreshing pilsner with a subtle hoppy finish.",
        },
        {
          id: 20,
          name: "Omega Amber",
          style: "Amber Ale",
          description: "A smooth and malty amber ale with a touch of caramel sweetness.",
        },
      ],
    },
  ];
  