import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-500 p-4">
      <div className="container mx-auto">
        <div className="flex justify-center items-center">
          <p className="text-white text-center">
            &copy; {new Date().getFullYear()} Cold One. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
