import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { mockBreweries } from "../MockData";
// import { fetchBreweries } from "../api"; // Replace this with your function to fetch brewery data

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 40.73061,
  lng: -73.935242,
};

const Map = () => {
    const [breweries, setBreweries] = useState(mockBreweries);
    const [selectedBrewery, setSelectedBrewery] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const breweryData = await fetchBreweries();
//       setBreweries(breweryData);
//     };
//     fetchData();
//   }, []);

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
        >
          {breweries.map((brewery) => (
            <Marker
              key={brewery.id}
              position={{ lat: brewery.latitude, lng: brewery.longitude }}
              onClick={() => {
                setSelectedBrewery(brewery);
              }}
            />
          ))}

          {selectedBrewery && (
            <InfoWindow
              position={{
                lat: selectedBrewery.latitude,
                lng: selectedBrewery.longitude,
              }}
              onCloseClick={() => {
                setSelectedBrewery(null);
              }}
            >
              <div>
                <h2>{selectedBrewery.name}</h2>
                <p>{selectedBrewery.address}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
      {/* Add your filter panel component here */}
    </div>
  );
};

export default React.memo(Map);
