import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import Card from "../components/Card";
import Form from "../components/Form";
import Input from "../components/Input";
import Button from "../components/Button";

const BreweryLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/brewery/dashboard");
    } catch (error) {
      console.error("Error logging in the brewery:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/brewery/dashboard");
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <Card className="w-96">
        <h2 className="text-2xl font-semibold mb-6">Brewery Login</h2>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" className="w-full">
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default BreweryLogin;
