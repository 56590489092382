import React, { useState, useEffect } from "react";
import { mockBreweries } from "../MockData";
import { NavLink } from "react-router-dom"; // Import NavLink

// import { fetchBreweries } from '../api'; // Replace this with your function to fetch brewery data from Firebase

const ListView = () => {
  const [breweries, setBreweries] = useState(mockBreweries);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const breweryData = await fetchBreweries();
  //     setBreweries(breweryData);
  //   };
  //   fetchData();
  // }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Breweries</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {breweries.map((brewery) => (
          <div
            key={brewery.id}
            className="border border-gray-300 rounded-lg p-4"
          >
            <NavLink to={`/breweries/${brewery.id}`}>{brewery.name}</NavLink>
            <p className="text-sm mb-2">{brewery.address}</p>
            <p className="text-sm">{brewery.description}</p>
            {/* Add a link to the brewery's profile page */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListView;
