import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mockBreweries } from "../MockData";
// import { fetchBrewery } from '../api'; // Replace this with your function to fetch brewery data

const containerStyle = {
  width: "100%",
  height: "50vh",
};

const BreweryProfile = () => {
  const { breweryId } = useParams();
  const [brewery, setBrewery] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // const breweryData = await fetchBrewery(breweryId);
      // setBrewery(breweryData);
      // Uncomment the above lines and remove the line below when you have your fetchBrewery function ready
      setBrewery(mockBreweries.find((b) => b.id === parseInt(breweryId)));
    };
    fetchData();
  }, [breweryId]);

  if (!brewery) {
    return <div>Loading...</div>;
  }

  const center = {
    lat: brewery.latitude,
    lng: brewery.longitude,
  };

  return (
    <div className="bg-brewery bg-cover bg-center min-h-screen">
      <div className="container mx-auto py-12 px-4">
        <div className="p-8 bg-white shadow-md rounded-lg">
          <h1 className="text-4xl font-semibold mb-4">{brewery.name}</h1>
          <p className="text-lg mb-4">{brewery.address}</p>
          {/* Add more brewery information here */}
          <h2 className="text-2xl font-semibold mb-4">About</h2>
          <p className="text-lg mb-4">
            {brewery.description || "Brewery description goes here."}
          </p>
          <section>
            <h2 className="text-3xl font-semibold mb-4">Beers</h2>
            <div className="space-y-4">
              <div className="beer-item flex items-start space-x-10">
                {brewery.beers.map((beer) => (
                  <div key={beer.id}>
                    {/* <img
                      src="/path/to/beer-icon.svg"
                      alt="Beer icon"
                      className="w-6 h-6"
                    /> */}
                    <h3 className="text-xl font-semibold mb-2">{beer.name}</h3>
                    {/* <p className="text-sm font-semibold text-gray-500 mb-2">
                    {beer.style}
                  </p> */}
                    <p className="text-sm text-gray-600">
                      {beer.style} - {beer.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="pt-8">
            <h2 className="text-2xl font-semibold mb-4">Location</h2>
            <div className="mb-8">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
              >
                <Marker position={center} />
              </GoogleMap>
            </div>
          </section>
          {/* Add more sections as needed */}
        </div>
      </div>
    </div>
  );
};

export default BreweryProfile;
