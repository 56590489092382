import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../firebaseConfig";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { FiEdit } from "react-icons/fi";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Modal from "../components/Modal";
import { StandaloneSearchBox } from "@react-google-maps/api";

const mockData = [
  { date: "2023-01", redemptions: 10 },
  { date: "2023-02", redemptions: 20 },
  { date: "2023-03", redemptions: 45 },
  { date: "2023-04", redemptions: 30 },
  { date: "2023-05", redemptions: 55 },
  { date: "2023-06", redemptions: 35 },
];

const BreweryDashboard = () => {
  const [breweryData, setBreweryData] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [beers, setBeers] = useState([]);
  const [newBeer, setNewBeer] = useState("");
  const [avgPrice, setAverageBeerPrice] = useState(0);
  const [location, setLocation] = useState("");
  // const [searchBox, setSearchBox] = useState(null);
  const searchBox = useRef(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const toggleModal = (content) => {
    setModalContent(content);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchBreweryData = async (uid) => {
      const brewerySnapshot = await getDoc(doc(firestore, "breweries", uid));
      if (brewerySnapshot.exists()) {
        console.log("Brewery Data:", brewerySnapshot.data());
        setBreweryData(brewerySnapshot.data());
      } else {
        console.log("No brewery data found for this UID.");
      }
    };

    if (auth.currentUser) {
      fetchBreweryData(auth.currentUser.uid);
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        fetchBreweryData(user.uid);
      } else {
        console.log("No user logged in.");
      }
    });

    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User UID:", user.uid);
        const brewerySnapshot = await getDoc(
          doc(firestore, "breweries", user.uid)
        );

        if (brewerySnapshot.exists()) {
          console.log("Brewery Data:", brewerySnapshot.data());
          setBreweryData(brewerySnapshot.data());
          setBeers(brewerySnapshot.data().beers || []);
          setAverageBeerPrice(brewerySnapshot.data().avgPrice || 0);
        } else {
          console.log("No brewery data found for this UID.");
        }
      } else {
        console.log("No user logged in.");
      }
    });

    return () => {
      unsubscribe();
      unsub();
    };
  }, [auth.currentUser, isModalOpen]);

  const handleAddBeer = async () => {
    if (newBeer.trim() === "") {
      return;
    }

    const updatedBeers = [...beers, newBeer];
    setBeers(updatedBeers);
    setNewBeer("");

    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      beers: updatedBeers,
    });
  };

  const handleRemoveBeer = async (beerToRemove) => {
    const updatedBeers = beers.filter((beer) => beer !== beerToRemove);
    setBeers(updatedBeers);

    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      beers: updatedBeers,
    });
  };

  const handleUpdateAverageBeerPrice = async () => {
    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      avgPrice: parseFloat(avgPrice),
    });
    toggleModal(null);
  };

  const handleLocationSelect = (place) => {
    setLocation(place.formatted_address);
    setLatitude(place.geometry.location.lat());
    setLongitude(place.geometry.location.lng());
  };

  const handleUpdateLocation = async () => {
    await updateDoc(doc(firestore, "breweries", auth.currentUser.uid), {
      location: location,
      latitude: latitude,
      longitude: longitude,
    });
    toggleModal(null);
  };

  if (!breweryData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto py-12 px-4">
        <h1 className="text-4xl font-semibold mb-10 pt-10">
          {breweryData.name}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="relative bg-white p-6 rounded-lg shadow-md">
            <FiEdit
              onClick={() => toggleModal("beers")}
              className=" text-2xl absolute top-4 right-4 text-gray-500 hover:text-gray-800 cursor-pointer"
            />
            <h2 className="text-2xl font-semibold mb-2">Beers</h2>
            <ul className="space-y-2">
              {breweryData.beers.map((beer, index) => (
                <li key={index}>{beer}</li>
              ))}
            </ul>
          </div>
          <div className="relative bg-white p-6 rounded-lg shadow-md">
            <FiEdit
              onClick={() => toggleModal("averageBeerPrice")}
              className="text-2xl absolute top-4 right-4 text-gray-500 hover:text-gray-800 cursor-pointer"
            />
            <h2 className="text-2xl font-semibold mb-4">Average Beer Price</h2>
            <p>${breweryData.averageBeerPrice.toFixed(2)}</p>
          </div>
          <div className="relative bg-white p-6 rounded-lg shadow-md">
            <FiEdit
              onClick={() => toggleModal("location")}
              className="text-2xl absolute top-4 right-4 text-gray-500 hover:text-gray-800 cursor-pointer"
            />
            <h2 className="text-2xl font-semibold mb-4">Location</h2>
            <p>{breweryData.location}</p>
          </div>
        </div>
        {/* ... */}
        <div className="w-full pt-8 mt-4">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-2xl font-semibold mb-4">Beer Redemptions</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={mockData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="redemptions"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="mt-10">
          {/* <Button className="mr-4" onClick={() => navigate("/beer-management")}>
            Manage Beers
          </Button> */}
          {/* <Button
            className="mr-4"
            onClick={() => navigate("/location-management")}
          >
            Manage Location
          </Button> */}
          {/* <Button onClick={handleSignOut}>Sign Out</Button> */}
        </div>
        {isModalOpen && (
          <Modal closeModal={() => toggleModal(null)}>
            {modalContent === "beers" && (
              <>
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Add a new beer"
                    className="border border-gray-300 p-2 rounded w-full"
                    value={newBeer}
                    onChange={(e) => setNewBeer(e.target.value)}
                  />
                  <Button className="w-full mt-2" onClick={handleAddBeer}>
                    Add Beer
                  </Button>
                </div>
                <ul>
                  {beers.map((beer, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between mb-2"
                    >
                      <span>{beer}</span>
                      <Button
                        className="text-red-500"
                        onClick={() => handleRemoveBeer(beer)}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {modalContent === "averageBeerPrice" && (
              <div className="mb-4">
                <label
                  htmlFor="avgPrice"
                  className="block text-sm font-medium text-gray-700"
                >
                  Average Beer Price
                </label>
                <input
                  type="number"
                  id="avgPrice"
                  name="avgPrice"
                  value={avgPrice}
                  onChange={(e) => setAverageBeerPrice(e.target.value)}
                  className="mt-1 block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <Button
                  className="w-full mt-2"
                  onClick={handleUpdateAverageBeerPrice}
                >
                  Update Average Beer Price
                </Button>
              </div>
            )}
            {modalContent === "location" && (
              <div className="mb-4">
                <StandaloneSearchBox
                  onLoad={(ref) => (searchBox.current = ref)}
                  onPlacesChanged={() => {
                    const place = searchBox.current.getPlaces()[0];
                    handleLocationSelect(place);
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter brewery location"
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </StandaloneSearchBox>
                <Button className="w-full mt-2" onClick={handleUpdateLocation}>
                  Update Location
                </Button>
              </div>
            )}
            <Button onClick={() => toggleModal(null)} className="...">
              Close
            </Button>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default BreweryDashboard;
